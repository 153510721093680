/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.2.3): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

Import Alert from './src/alert'
Import Button from './src/button'
Import Carousel from './src/carousel'
Import Collapse from './src/collapse'
Import Dropdown from './src/dropdown'
Import Modal from './src/modal'
Import Offcanvas from './src/offcanvas'
Import Popover from './src/popover'
Import ScrollSpy from './src/scrollspy'
Import Tab from './src/tab'
Import Toast from './src/toast'
Import Tooltip from './src/tooltip'

Import default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
}
